<template lang="pug">
  div
    Alert(type="incoming")
    v-data-table.elevation-1(:headers="columns" :items="filteredStatements" :disable-pagination="true" :disable-sort="true" item-key="id")
      template(#item.paid="{ item }")
        v-chip.status(outlined pill color="green" v-show="item.paid")
          v-icon(small left) mdi-currency-usd
          | Received
        v-chip.status(outlined pill color="red" v-show="!item.paid")
          v-icon(small left) mdi-currency-usd-off
          | Awaiting
      template(#item.value="{ item }")
        .amount {{item.formatted.value}}
      template(#item.fee="{ item }")
        .fee {{item.formatted.fee}}
      template(#item.date="{ item }")
        | {{ parseDate(item.date_added) }}
      template(#item.actions="{ item }")
        v-menu(bottom left)
          template(#activator="{ on, attrs }")
            v-btn(icon v-on="on" v-bind="attrs" )
              v-icon(color="primary") mdi-dots-vertical
          v-list.actions-list
            v-subheader Actions for "{{item.name}}"
            v-list-item(v-show="!item.paid" @click="setPaid(item)")
              v-list-item-icon
                v-icon mdi-currency-usd
              v-list-item-title Set as received
            v-list-item(v-if="item.paid" @click="setUnpaid(item)")
              v-list-item-icon
                v-icon mdi-currency-usd-off
              v-list-item-title Set as awaiting
            v-list-item(@click="previewSummary(item)")
              v-list-item-icon
                v-icon mdi-file
              v-list-item-title Statement summary
            v-list-item(@click="downloadSummary(item)")
              v-list-item-icon
                v-icon mdi-download
              v-list-item-title Download summary
            v-list-item(@click="rebuildStatement(item)")
              v-list-item-icon
                v-icon mdi-refresh
              v-list-item-title Rebuild statement
            v-list-item(@click="showInPeoplePage(item)" v-if="item.paid")
              v-list-item-icon
                v-icon mdi-inbox-arrow-up-outline
              v-list-item-title Show in outgoing
            v-list-item(@click="deleteStatement(item)")
              v-list-item-icon
                v-icon mdi-delete
              v-list-item-title Delete
      template(v-slot:header.client="{ header }")
        | {{ header.text }}
        v-menu(:close-on-content-click="false" @input="hide($event, 'clients')" v-model="filters.clients.dropdown")
          template(v-slot:activator="{ on, attrs }")
            v-icon(v-bind="attrs" v-on="on" :class="{active: filters.clients.value.length}") mdi-filter
          .v-menu__content--filter
            v-list(dense)
              v-subheader Source
              v-autocomplete(:items="clients" item-text="name" item-value="id" v-model="filters.clients.input" multiple @input="filterBySource")
                template(#selection="{ item, index }")
                  v-chip(v-if="index === 0")
                    span {{ item.name }}
                  span.grey--text.text-caption(v-if="index === 1")
                    | (+{{ filters.clients.input.length - 1 }} hidden)
            v-btn(color="primary" small text @click="reset('clients')" v-show="filters.clients.value.length") Reset
      template(v-slot:header.date="{ header }")
        | {{ header.text }}
        v-menu(:close-on-content-click="false" @input="hide($event, 'date')" v-model="filters.date.dropdown")
          template(v-slot:activator="{ on, attrs }")
            v-icon(v-bind="attrs" v-on="on" :class="{active: filters.date.value && filters.date.value.length > 0}") mdi-calendar-range
          .v-menu__content--filter
            v-list(dense)
              v-subheader Date
              v-list-item
                Datepicker(v-model="filters.date.input")
            v-btn(color="primary" @click="filter('date')") Filter
            v-btn(color="primary" small text @click="reset('date')" v-show="filters.date.value") Reset
      template(v-slot:header.name="{ header }")
        | {{ header.text }}
        v-menu(:close-on-content-click="false" @input="hide($event, 'name')" v-model="filters.name.dropdown")
          template(v-slot:activator="{ on, attrs }")
            v-icon(v-bind="attrs" v-on="on" :class="{active: filters.name.value}") mdi-magnify
          .v-menu__content--filter
            v-subheader Statement name
            v-text-field(label="Filter by name" dense v-model="filters.name.input")
            v-btn(color="primary" @click="filter('name')") Filter
            v-btn(color="primary" small text @click="reset('name')" v-show="filters.name.value") Reset
      template(v-slot:header.paid="{ header }")
        | {{ header.text }}
        v-menu(:close-on-content-click="false" @input="hide($event, 'paid')" v-model="filters.paid.dropdown")
          template(v-slot:activator="{ on, attrs }")
            v-icon(v-bind="attrs" v-on="on" :class="{active: filters.paid.value}") mdi-currency-usd
          .v-menu__content--filter
            v-list(dense)
              v-subheader Status
              v-list-item
                v-select(:items="paidFilterItems" v-model="filters.paid.input" item-text="name" item-value="value")
            v-btn(color="primary" @click="filter('paid')") Filter
            v-btn(color="primary" small text @click="reset('paid')" v-show="filters.paid.value") Reset
    v-pagination(v-model="filters.page.value" circle  :length="statementData.total_pages" total-visible="10")
</template>

<script>
import { mapState } from "vuex";
import Alert from "@/components/Alert";
import api from '@/api'
import Datepicker from "./Datepicker";

export default {
  data() {
      return {
        filters: {

          clients: {
            dropdown: false,
            value: [],
            input: []
          },

          date: {
            dropdown: false,
            value: null,
            input: [],
          },
          name: {
            dropdown: false,
            value: null,
            input: null
          },
          paid: {
            dropdown: false,
            value: null,
            input: null
          },
          page: {
            value: 1,
          },
        },
        dates: [
          {
            name: 'Today',
            value: '1 day'
          },
          {
            name: 'This week',
            value: '1 week'
          },
          {
            name: 'This month',
            value: '1 month'
          },
          {
            name: 'This year',
            value: '1 year'
          }
        ],
        paidFilterItems: [
          {
            name: "All",
            value: null
          },
          {
            name: "Received",
            value: 'paid'
          },
          {
            name: "Awaiting",
            value: 'unpaid'
          }
        ],
        columns: [],
      };
  },
  components: {
    Datepicker,
    Alert
  },
  computed: {
    filteredStatements(){
      return this.statements
    },
    ...mapState({
      statementData: (state) => state.incoming.statements,
      statements: (state) => state.incoming.statements.items,
      clients: (state) => state.incoming.clients,
      artists: (state) => state.songs.artists,
    }),
  },
  methods:{
    async setPaid(row, noAlert){
      await this.$store.dispatch('incoming/updatePaidStatus', {
        id: row.id,
        paid: true
      });
      if(!noAlert){
        this.$store.commit('popups/addIncomingAlert', {
          type: 'toggle-paid',
          action: 'paid',
          name: row.name,
          client: row.client,
          callback: () => this.setUnpaid(row, true)
        })
      }else{
        this.soundEffectWithoutAlert('paid');
      }
    },
    async setUnpaid(row, noAlert){
      await this.$store.dispatch('incoming/updatePaidStatus', {
        id: row.id,
        paid: false
      });
      if(!noAlert){
        this.$store.commit('popups/addIncomingAlert', {
          type: 'toggle-paid',
          action: 'unpaid',
          client: row.client,
          name: row.name,
          callback: () => this.setPaid(row, true)
        })
      }else{
        this.soundEffectWithoutAlert('unpaid');
      }
    },
    soundEffectWithoutAlert(sound){
      this.$store.commit('popups/playSoundEffect', sound);
    },
    showInPeoplePage(item){
      this.$store.commit('outgoing/setFilteredStatement', item)
      this.$router.push('/outgoing/' + item.id);
    },
    downloadSummary(item){
      window.location = `${api.api}/export/pdf/incoming-statement/${item.id}/download`;
    },
    previewSummary(item){
      window.open(`${api.api}/export/pdf/incoming-statement/${item.id}`, '_blank');
    },
    async rebuildStatement(item){
      await api.rebuildStatement(item.id);
      this.$store.commit('popups/addIncomingAlert', {
        type: 'rebuild',
        name: item.name,
        client: item.client,
      })

    },
    async deleteStatement(row){
      await this.$store.dispatch('incoming/deleteStatement', row.id);

      this.$store.commit('popups/addIncomingAlert', {
        type: 'delete',
        name: row.name,
        client: row.client,
        callback: () => this.restoreStatement({id: row.id, client: row.client, name: row.name})
      })
    },
    async restoreStatement(row){
      await this.$store.dispatch('incoming/restoreStatement', row.id);
      this.$store.commit('popups/addIncomingAlert', {
        type: 'restore',
        name: row.name,
        client: row.client
      })
    },

    parseDate(dateString){
      const date = new Date(dateString);
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    },

    filter(name){
      this.filters[name].value = this.filters[name].input;
      this.filters[name].dropdown = false;
      this.filters.page.value = 1;

      this.getStatements();
    },

    reset(name){
      if(typeof this.filters[name].value === 'object'){
        this.filters[name].input = [];
        this.filters[name].value = [];
      }else{
        this.filters[name].input = null;
        this.filters[name].value = null;
      }
      this.filters[name].dropdown = false;
      this.filters.page.value = 1;

      this.getStatements();
    },
    filterBySource(){
      this.filters.clients.value = this.filters.clients.input;
      this.filters.page.value = 1;
      this.getStatements();
    },
    setupTable(){
      this.columns = [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Source',
          value: 'client',
        },
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'Amount',
          value: 'value',
        },
        {
          text: 'Fee',
          value: 'fee',
        },
        {
          text: 'Status',
          value: 'paid',
        },
        {
          text: '',
          value: 'actions',
        },
      ]
    },
    addSong(){
      let id = 0;
      if(this.songs.length){
        id = this.songs[this.songs.length -1].id + 1;
      }
      this.songs = [...this.songs, {
        id: id,
        name: '',
        artist: '',
        value: 0
      }]
    },
    async addStatement(){
      const songs = await this.$store.dispatch("songs/processSongs", this.songs);
      await this.$store.dispatch("incoming/addStatement", {
        name: this.name,
        clientId: this.client,
        dateStart: '2010-01-01',
        dateEnd: '2010-01-01',
        songs: songs
      });
    },
    hide(shown, name){
      if(!shown){
        this.filters[name].input = this.filters[name].value;
      }
    },
    async getStatements(){
      return await this.$store.dispatch("incoming/getStatements", this.filters);
    }
  },
  async mounted() {
    await this.getStatements();
    this.setupTable();
  },

  watch: {
    'filters.page.value'() {
      this.getStatements();
    },
  }
};
</script>


<style lang="scss">
@import "../styles/variables";
.payment-action{
  cursor: pointer;

  &.unpaid{
    color: #0395E8;
  }
  &.paid{
    color: #7A7A7A;
  }

  .add-button{
    border-radius: 100%;
    font-size: 35px;
  }
}

.v-icon.selected-icon{
  position: absolute;
  right: 10px;
}

.v-icon.v-icon--link.active{
  color: #0000FF;
}

.v-data-table{
  .v-data-table-header{
    .v-icon{
      margin-left: 10px;
    }
  }

  .v-data-footer{
    display: none;
  }






}
.amount{
  font-weight: bold;
  color: $gray
}
.fee{
  font-weight: bold;
  color: $gray
}
.actions-list{
  .v-list-item{
    cursor: pointer;
    text-align: left;

    .v-list-item__icon{
      margin-right: 15px;
    }
    &:hover{
      background-color: $secondary;
    }

  }
}
.v-menu__content{
  max-width: 300px;
}

.v-chip.status{
}

</style>
