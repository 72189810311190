var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Alert',{attrs:{"type":"incoming"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.columns,"items":_vm.filteredStatements,"disable-pagination":true,"disable-sort":true,"item-key":"id"},scopedSlots:_vm._u([{key:"item.paid",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.paid),expression:"item.paid"}],staticClass:"status",attrs:{"outlined":"","pill":"","color":"green"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-currency-usd")]),_vm._v("Received")],1),_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(!item.paid),expression:"!item.paid"}],staticClass:"status",attrs:{"outlined":"","pill":"","color":"red"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-currency-usd-off")]),_vm._v("Awaiting")],1)]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"amount"},[_vm._v(_vm._s(item.formatted.value))])]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fee"},[_vm._v(_vm._s(item.formatted.fee))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.parseDate(item.date_added)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"actions-list"},[_c('v-subheader',[_vm._v("Actions for \""+_vm._s(item.name)+"\"")]),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(!item.paid),expression:"!item.paid"}],on:{"click":function($event){return _vm.setPaid(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-currency-usd")])],1),_c('v-list-item-title',[_vm._v("Set as received")])],1),(item.paid)?_c('v-list-item',{on:{"click":function($event){return _vm.setUnpaid(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-currency-usd-off")])],1),_c('v-list-item-title',[_vm._v("Set as awaiting")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.previewSummary(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file")])],1),_c('v-list-item-title',[_vm._v("Statement summary")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.downloadSummary(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-download")])],1),_c('v-list-item-title',[_vm._v("Download summary")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.rebuildStatement(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-list-item-title',[_vm._v("Rebuild statement")])],1),(item.paid)?_c('v-list-item',{on:{"click":function($event){return _vm.showInPeoplePage(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-inbox-arrow-up-outline")])],1),_c('v-list-item-title',[_vm._v("Show in outgoing")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.deleteStatement(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)]}},{key:"header.client",fn:function(ref){
var header = ref.header;
return [_vm._v(_vm._s(header.text)),_c('v-menu',{attrs:{"close-on-content-click":false},on:{"input":function($event){return _vm.hide($event, 'clients')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:{active: _vm.filters.clients.value.length}},'v-icon',attrs,false),on),[_vm._v("mdi-filter")])]}}],null,true),model:{value:(_vm.filters.clients.dropdown),callback:function ($$v) {_vm.$set(_vm.filters.clients, "dropdown", $$v)},expression:"filters.clients.dropdown"}},[_c('div',{staticClass:"v-menu__content--filter"},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Source")]),_c('v-autocomplete',{attrs:{"items":_vm.clients,"item-text":"name","item-value":"id","multiple":""},on:{"input":_vm.filterBySource},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v("(+"+_vm._s(_vm.filters.clients.input.length - 1)+" hidden)")]):_vm._e()]}}],null,true),model:{value:(_vm.filters.clients.input),callback:function ($$v) {_vm.$set(_vm.filters.clients, "input", $$v)},expression:"filters.clients.input"}})],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.filters.clients.value.length),expression:"filters.clients.value.length"}],attrs:{"color":"primary","small":"","text":""},on:{"click":function($event){return _vm.reset('clients')}}},[_vm._v("Reset")])],1)])]}},{key:"header.date",fn:function(ref){
var header = ref.header;
return [_vm._v(_vm._s(header.text)),_c('v-menu',{attrs:{"close-on-content-click":false},on:{"input":function($event){return _vm.hide($event, 'date')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:{active: _vm.filters.date.value && _vm.filters.date.value.length > 0}},'v-icon',attrs,false),on),[_vm._v("mdi-calendar-range")])]}}],null,true),model:{value:(_vm.filters.date.dropdown),callback:function ($$v) {_vm.$set(_vm.filters.date, "dropdown", $$v)},expression:"filters.date.dropdown"}},[_c('div',{staticClass:"v-menu__content--filter"},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Date")]),_c('v-list-item',[_c('Datepicker',{model:{value:(_vm.filters.date.input),callback:function ($$v) {_vm.$set(_vm.filters.date, "input", $$v)},expression:"filters.date.input"}})],1)],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.filter('date')}}},[_vm._v("Filter")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.filters.date.value),expression:"filters.date.value"}],attrs:{"color":"primary","small":"","text":""},on:{"click":function($event){return _vm.reset('date')}}},[_vm._v("Reset")])],1)])]}},{key:"header.name",fn:function(ref){
var header = ref.header;
return [_vm._v(_vm._s(header.text)),_c('v-menu',{attrs:{"close-on-content-click":false},on:{"input":function($event){return _vm.hide($event, 'name')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:{active: _vm.filters.name.value}},'v-icon',attrs,false),on),[_vm._v("mdi-magnify")])]}}],null,true),model:{value:(_vm.filters.name.dropdown),callback:function ($$v) {_vm.$set(_vm.filters.name, "dropdown", $$v)},expression:"filters.name.dropdown"}},[_c('div',{staticClass:"v-menu__content--filter"},[_c('v-subheader',[_vm._v("Statement name")]),_c('v-text-field',{attrs:{"label":"Filter by name","dense":""},model:{value:(_vm.filters.name.input),callback:function ($$v) {_vm.$set(_vm.filters.name, "input", $$v)},expression:"filters.name.input"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.filter('name')}}},[_vm._v("Filter")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.filters.name.value),expression:"filters.name.value"}],attrs:{"color":"primary","small":"","text":""},on:{"click":function($event){return _vm.reset('name')}}},[_vm._v("Reset")])],1)])]}},{key:"header.paid",fn:function(ref){
var header = ref.header;
return [_vm._v(_vm._s(header.text)),_c('v-menu',{attrs:{"close-on-content-click":false},on:{"input":function($event){return _vm.hide($event, 'paid')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:{active: _vm.filters.paid.value}},'v-icon',attrs,false),on),[_vm._v("mdi-currency-usd")])]}}],null,true),model:{value:(_vm.filters.paid.dropdown),callback:function ($$v) {_vm.$set(_vm.filters.paid, "dropdown", $$v)},expression:"filters.paid.dropdown"}},[_c('div',{staticClass:"v-menu__content--filter"},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Status")]),_c('v-list-item',[_c('v-select',{attrs:{"items":_vm.paidFilterItems,"item-text":"name","item-value":"value"},model:{value:(_vm.filters.paid.input),callback:function ($$v) {_vm.$set(_vm.filters.paid, "input", $$v)},expression:"filters.paid.input"}})],1)],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.filter('paid')}}},[_vm._v("Filter")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.filters.paid.value),expression:"filters.paid.value"}],attrs:{"color":"primary","small":"","text":""},on:{"click":function($event){return _vm.reset('paid')}}},[_vm._v("Reset")])],1)])]}}])}),_c('v-pagination',{attrs:{"circle":"","length":_vm.statementData.total_pages,"total-visible":"10"},model:{value:(_vm.filters.page.value),callback:function ($$v) {_vm.$set(_vm.filters.page, "value", $$v)},expression:"filters.page.value"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }