<template lang="pug">
  v-dialog(:value="show" width="750" @input="hide" persistent)
    v-card
      v-card-title.headline.primary
        | Add incoming statement
        v-spacer
        v-btn.close(icon @click="hide")
          v-icon(color="white") mdi-close
      v-card-text
        StatementInput(:statement="statement" @add-song="addSong" @delete-song="deleteSong")

      v-divider
      v-card-actions
        v-tooltip(top) Refresh Airtables
          template(#activator="{ on, attrs }")
            v-btn.refresh-airtables(fab small  @click="refreshAirtables" v-bind="attrs" v-on="on")
              v-icon mdi-refresh
        v-btn( @click="uploadFile") File upload
        v-spacer
        v-btn(color="primary" @click="addStatement")
          | Save statement
</template>

<script>
import {mapState} from "vuex";
import StatementInput from "@/components/StatementInput";

export default {
  computed: {
    ...mapState({
      show: (state) => state.popups.incomingStatement,
    }),
  },


  methods: {
    hide(){
      this.$store.commit('popups/hideIncomingStatementsPopup');
      this.resetStatement();
    },
    async addStatement(){
      await this.$store.dispatch("incoming/addStatement", {
        name: this.statement.name,
        clientId: this.statement.client,
        songs: this.statement.inputSongs,
        date: this.statement.date
      });
      this.$store.commit('popups/addIncomingAlert', {
        name: this.statement.name,
        type: 'incoming-added',
        client: this.statement.clientName
      })

      this.hide();
    },
    addSong(){
      this.statement.inputSongs = [...this.statement.inputSongs, {
        id: null,
        value: null
      }]
    },
    deleteSong(index){
      this.statement.inputSongs.splice(index, 1);
    },
    uploadFile(){
      this.hide();
      this.$store.commit('popups/showIncomingStatementUploadPopup');
    },
    async refreshAirtables(){
      await this.$store.commit('popups/showRefreshPopup')
    },
    resetStatement(){
      this.statement = {
        inputSongs: [],
        client: 0,
        clientName: '',
        name: '',
        date: []
      };
      this.addSong();
    },
  },
  components:{
    StatementInput
  },
  data() {
    return {
      statement: {
        inputSongs: [],
        client: 0,
        clientName: '',
        name: '',
        date: []
      }
    }
  },
  mounted() {
    this.addSong();
  }
};
</script>

<style scoped lang="scss">
.headline{
  color: #FFFFFF;
}

.v-card__actions{

  position: relative;

  .refresh-airtables{
    position: absolute;
    margin-left: -12px;
    left: 50%;
  }
}

.v-btn.close{
  position: relative;

  top: -5px;
  right: -5px;
}
</style>
