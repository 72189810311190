import api from "../../api";
const state = {
  statements: {
    items: [],
    total_pages: 1
  },
  clients: [],
  filters: {},
  parsers: [],
  fields: {}
};
const getters = {};
const actions = {
  async getStatements({ commit, state }, filters) {
    if(filters){
      commit("setFilters", filters);
    }
    const statements = await api.getIncomingStatements(state.filters);
    commit("setStatements", statements);
  },
  async getStatement(store, id) {
    return api.getIncomingStatement(id);
  },
  async getIncomingStatementParsers({commit}) {
    const parsers = await api.getIncomingStatementParsers();
    commit("setIncomingStatementParsers", parsers);
  },
  async downloadStatementSummary(store, id) {
    return api.downloadStatementSummary(id);
  },
  async getClients({ commit }) {
    const clients = await api.getClients();
    commit("setClients", clients);
  },
  async getIncomingStatementFields({commit}) {
    const fields = await api.getIncomingStatementFields();
    commit("setIncomingStatementFields", fields);
  },
  async addStatement({ dispatch }, statement) {
    await api.addIncomingStatement(statement);
    dispatch('getStatements');
    dispatch('outgoing/getPeople', null, { root: true });
  },
  async updatePaidStatus({ dispatch}, statement) {
    await api.updatePaidStatus(statement.id, statement.paid);
    dispatch('getStatements');
    dispatch('outgoing/getPeople', null, { root: true });
  },
  async deleteStatement({dispatch}, statementId){
    await api.deleteStatement(statementId)
    dispatch('getStatements');
    dispatch('outgoing/getPeople', null, { root: true });
  },
  async restoreStatement({dispatch}, statementId){
    await api.restoreStatement(statementId)
    dispatch('getStatements');
    dispatch('outgoing/getPeople', null, { root: true });
  },
  async deleteParser({dispatch}, parserId){
    await api.deleteParser(parserId)
    dispatch('getIncomingStatementParsers');
  },
};
const mutations = {
  setStatements(state, statements) {
    state.statements = statements;
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
  setClients(state, clients) {
    state.clients = clients;
  },


  addStatement(state, statement){
    state.statements.push(statement);
  },

  setIncomingStatementParsers(state, parsers){
    state.parsers = parsers;
  },

  setIncomingStatementFields(state, fields){
    state.fields = fields;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
