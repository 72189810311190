import api from "../../api";

const state = {
  user: null,
};
const getters = {
  isLoggedIn: state => {
    return state.user && state.user.id
  }
};
const actions = {
  async getUser({ commit }) {
    const user = await api.getUser();
    commit("setUser", user);
  },
  async login({ commit }, user) {
    const response = await api.login(user.email, user.password);
    if(response.success){
      commit("setUser", response.user);
      commit("setIsLoggedIn", true);
    }else{
      commit("setUser", null);
      commit("setIsLoggedIn", false);
    }
    return response;
  },
  async logout({ commit }) {
    await api.logout();
    commit("setUser", null);
  },

};

const mutations = {
  setIsLoggedIn(state, isLoggedIn) {
    state.isLoggedIn = isLoggedIn;
  },
  setUser(state, user) {
    state.user = user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
