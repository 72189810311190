<template lang="pug">
  div
    v-alert(v-for="(alert, index) in alerts" :key="index" dismissible :type="typeMap[alert.type]" v-model="alert.visible"  text outlined)
      template(v-if="alert.type === 'incoming-added'")
        | Successfully added statement &nbsp;
        b {{alert.name}}&nbsp;
        | from&nbsp;
        b {{alert.client}}
        | .
      template(v-if="alert.type === 'toggle-paid'")
        | Successfully set statement &nbsp;
        b {{alert.name}}&nbsp;
        | from&nbsp
        b {{alert.client}}&nbsp;
        span(v-show="alert.person") for &nbsp;
        b(v-show="alert.person") {{alert.person}}&nbsp;
        | as&nbsp;
        span(v-if="alert.action === 'paid'") paid.&nbsp;
        span(v-else) unpaid.&nbsp;
        a(@click="callback(alert.callback, alert)") Undo
        | .
      template(v-if="alert.type === 'delete'")
        | Successfully deleted statement &nbsp;
        b {{alert.name}}&nbsp;
        | from&nbsp
        b {{alert.client}}
        | .&nbsp;
        a(@click="callback(alert.callback, alert)") Undo
        | .
      template(v-if="alert.type === 'restore'")
        | Successfully restored statement &nbsp;
        b {{alert.name}}&nbsp;
        | from&nbsp
        b {{alert.client}}
        | .
      template(v-if="alert.type === 'rebuild'")
        | Successfully rebuilt statement &nbsp;
        b {{alert.name}}&nbsp;
        | from&nbsp
        b {{alert.client}}
        | .
</template>

<script>
export default {
  props: ['type'],
  computed: {
    alerts() {
      if(this.type){
        return this.$store.state.popups.alerts[this.type]
      }
      return [];
    }
  },
  data(){
    return {
      typeMap: {
        'incoming-added': 'success',
        'toggle-paid': 'warning',


        'delete': 'error',
        'restore': 'success'
      },
      timeouts: {
        'incoming-added': 15,
        'toggle-paid': 15,
        'delete': 300,
        'restore': 15
      },
    }
  },
  methods: {
    callback(callback, alert){
      alert.visible = false;
      callback();
    }
  },
  watch: {
    'alerts': {
      deep: true,
      handler: function(alerts){
        const alert = alerts[alerts.length -1];
        setTimeout(() => alert.visible = false, this.timeouts[alert.type] * 1000);
        if(!alert.sound){
          let soundEffect = alert.type;
          if(alert.type === 'toggle-paid'){
            soundEffect = 'paid';
            if(alert.action !== 'paid'){
              soundEffect = 'unpaid'
            }
          }
          alert.sound = true;
          this.$store.commit('popups/playSoundEffect', soundEffect);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.v-alert{
  text-align: left;
}
</style>
