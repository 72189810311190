<template lang="pug">
  v-menu(ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto")
    template(v-slot:activator="{ on, attrs }")
      v-text-field(v-model="formattedDate" label="Pick single / range" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on")
    v-date-picker(v-model="date" no-title scrollable range)
      v-btn(text @click="menu = false")
        | Dismiss
      v-btn(text color="primary" @click="setDate")
        | OK
</template>

<script>
export default {
  props: ['value'],
  data(){
    return {
      menu: false,
      date: [],
      formattedDate: ''
    }
  },
  computed: {
  },
  methods: {




    setDate(){
      this.setFormattedDate();
      this.$refs.menu.save(this.date);
      this.$emit('input', this.date);
    },
    setFormattedDate(){
      if(this.date.length === 0){
        this.formattedDate = '';
        return;
      }
      if(this.date.length === 1 || (this.date.length === 2 && this.date[0] === this.date[1])){
        this.formattedDate = this.formatDate(this.date[0]);
      }
      if(this.date.length === 2 && this.date[0] !== this.date[1]){
        const date = [...this.date].sort();
        this.formattedDate = `${this.formatDate(date[0])} - ${this.formatDate(date[1])}`;
      }
    },
    formatDate(dateString){
      let date = new Date(dateString);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if(month < 10){
        month = `0${month}`
      }
      if(day < 10){
        day = `0${day}`
      }

      return `${month}/${day}/${year}`;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function(){
        if(this.value && typeof this.value === 'object' && this.value.length >= 0){
          this.date = this.value;
          this.setFormattedDate();
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
</style>
