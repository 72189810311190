<template lang="pug">
  .mapping-table
    .table-titles
      .table-title(v-for="field in fields" :class="field.id") {{field.name}}
        div
          v-icon mdi-arrow-down
    .table-data
      .table-row
        .table-field.field-mapping(v-for="field in fields" :class="field.id")
          v-select(v-model="parser.field_map[field.id]" :items="titleRow" item-text="title" item-value="id" dense :disabled="hasParser")

      .table-row(v-show="showPatterns")
        .table-field.field-pattern(v-for="field in fields" :class="[field.id, {'regexp-error': regexpErrors[field.id]}]")
          v-text-field(v-model="parser.field_regexp[field.id]" dense  single-line  :label="`${field.name} pattern`" :error="regexpErrors[field.id]" @input="validateRegexp(parser.field_regexp[field.id], field.id)"  :disabled="hasParser")



      .table-row(v-for="(row, index) in sampleRows" v-show="showSamples")
        .table-field(v-for="field in fields" :class="field.id")
          .row-parsed-value(v-if="parser.field_regexp[field.id] && !isNaN(parser.field_map[field.id])")
            v-tooltip.processed-tooltip(top)
              template(v-slot:activator="{ on, attrs }")
                v-icon(v-bind="attrs" v-on="on") mdi-text-search
              .tooltip-title Processed text
              .tooltip-text
                span.original-label Original:&nbsp;
                span.original-text {{row[parser.field_map[field.id]]}}
            |  {{ parseRow(row[parser.field_map[field.id]], parser.field_regexp[field.id], field.id) }}

          .row-value(v-else-if="!isNaN(parser.field_map[field.id]) && parser.field_map[field.id] !== null") {{row[parser.field_map[field.id]]}}
          .row-na(v-else) N/A
</template>

<script>
import {mapState} from "vuex";

export default {
  props: ['allRows', 'showPatterns', 'parser'],
  data(){
    return {
      regexpErrors: {}
    }
  },
  methods: {


    parseRow(row, regex, fieldId) {
      let parsed = '';
      if(row && regex && fieldId){
        try{
          let regexp = new RegExp(regex);
          const matches = row.match(regexp);
          const selectedMatches = matches.slice(1);
          parsed = selectedMatches.join('');
        }catch (e){
          return parsed;
        }
      }
      return parsed;
    },

    validateRegexp(regex, fieldId) {
      if(regex && fieldId){
        try{
          const text = 'Sample';
          let regexp = new RegExp(regex);
          text.match(regexp);
        }catch (e){
          this.$set(this.regexpErrors, fieldId, true);
          this.errorEvent();

          return false;
        }
      }
      this.$set(this.regexpErrors, fieldId, false);
      this.errorEvent();
    },

    errorEvent(){
      const errors = Object.values(this.regexpErrors).filter(error => error);
      this.$emit('regexpError', errors.length > 0);
    },

    setParserErrors(){
      this.fields.forEach(field => this.$set(this.regexpErrors, field.id, false));
    }

  },
  computed: {

    hasParser(){
      if(this.parser.id){
        return true;
      }
      return false
    },

    rows(){
      return this.allRows.slice(0, Math.min(this.allRows.length, 6))
    },
    showSamples(){
      const mappings = Object.values(this.parser.field_map).filter(index => index >= 0);
      return mappings.length > 0;
    },

    titleRow(){
      let row = [];
      if(this.rows[0]){
        row = this.rows[0];
      }
      let rowItems =
        row.map((item, index) => ({
          id: index,
          title: item,
          disabled: Object.values(this.parser.field_map).includes(index)
        }));
      return [
        {
          id: null,
          title: '(None)',
          disabled: false
        },
        ...rowItems]
    },

    sampleRows() {
      let rows = [];
      if(this.rows.length > 1){
        rows = this.rows.slice(1);
      }
      return rows;
    },
    ...mapState({
      fields: (state) => state.incoming.fields,
    }),

  },

  async mounted() {
    await this.$store.dispatch('incoming/getIncomingStatementFields');
    this.setParserErrors();
  },
};
</script>

<style scoped lang="scss">
@import "../styles/variables";

.mapping-table{

  .artist{
    width: 30%;
  }

  .name{
    width: 30%;
  }

  .type{
    width: 25%;
  }

  .value{
    width: 15%;
  }

  .table-title{
    display: inline-block;

    font-size: 15px;
    padding: 10px;

    font-weight: bold;

    text-align: center;
    border: 1px solid #EEEEEE;
    background-color: $primary;
    color: #FFFFFF;

    div{
      margin-top: 5px;

      i{
        color: #FFFFFF;
        font-weight: normal;
      }

    }

  }

  .table-field{
    display: inline-block;
    height: 35px;
    text-align: left;
    border:none;
    border-bottom: 1px solid #EEEEEE;

    padding: 5px;
    border-left: 1px solid #EEEEEE;
    border-right: 1px solid #EEEEEE;

    &.field-mapping{
      height: auto;
    }

    &.field-pattern{
      height: auto;
    }

    &::v-deep .v-text-field__details{
      display: none;
    }

    &::v-deep .v-input__slot:before{
      display: none;
    }

    &::v-deep .v-input__slot:after{
      display: none;
    }

    &.field-pattern{
      background-color: rgba($success, 0.15);

      &.regexp-error{
        background-color: rgba($error, 0.15);
      }
    }
  }
}

.row-value{
  color: $success;
  font-size: 17px;

  &.raw{
    color: $gray;
    text-decoration: line-through;

    font-size: 12px;
    opacity: 0.5;
  }
}

.row-parsed-value{
  color: $success;
  font-size: 15px;
}



.tooltip-title{
  font-weight: bold;
  margin-bottom: 5px;

  font-size: 15px;
}

.tooltip-text{

  .original-label{
    font-weight: bold;
  }

}

.processed-tooltip{
  display: inline-block;
}

</style>
