import axios from "axios";

//const api = 'http://localhost/api/v1';
const api = 'http://api.soundcuts.3theorymusic.com/api/v1';
export default {
  api: api,
  async getSongs() {
    const response = await axios.get(`${api}/songs`);
    return response.data;
  },
  async getSongsByName(name) {
    const response = await axios.get(`${api}/songs/filter`, {
      params: {
        name: name
      }
    });
    return response.data;
  },
  async getFullSongs(filters) {
    const response = await axios.get(`${api}/songs/full`, {
      params: {
        name: filters.name.value,
        artists: filters.artists.value,
        page: filters.page.value,
        not_full: filters.not_full.value
      }
    });
    return response.data;
  },

  async getPeopleForSong(id) {
    const response = await axios.get(`${api}/songs/${id}/people`);
    return response.data;
  },

  async getArtists() {
    const response = await axios.get(`${api}/artists`);
    return response.data;
  },

  async getClients() {
    const response = await axios.get(`${api}/clients`);
    return response.data;
  },

  async getIncomingStatements(filters) {
    const response = await axios.get(`${api}/incoming-statements`, {
      params: {
        clients: filters.clients.value,
        date: filters.date.value,
        name: filters.name.value,
        paid: filters.paid.value,
        page: filters.page.value,
      }
    });
    return response.data;
  },

  async getIncomingStatementParsers() {
    const response = await axios.get(`${api}/incoming-statements/parsers`, );
    return response.data;
  },

  async getIncomingStatementFields() {
    const response = await axios.get(`${api}/incoming-statements/fields`, );
    return response.data;
  },

  async addIncomingStatement(statement){
    const response = await axios.post(`${api}/incoming-statements`, statement);
    return response.data;
  },

  async rebuildStatement(id){
    const response = await axios.get(`${api}/incoming-statements/rebuild/${id}`);
    return response.data;
  },

  async getIncomingStatement(id) {
    const response = await axios.get(`${api}/incoming-statements/${id}`);
    this.downloadFile(response.data, 'file.pdf');
  },

  async processSongs(songs){
    const response = await axios.post(`${api}/songs/process`, {
      songs: songs
    });
    return response.data;
  },

  async updatePaidStatus(id, status){
    const response = await axios.put(`${api}/incoming-statements/${id}`, {
      paid: status
    });
    return response.data;
  },

  async getPeople(filters) {
    const response = await axios.get(`${api}/people`, {
      params: {
        name: filters.name.value || null,
        statement: filters.statement.value || null,
        date: filters.date.value || null,
        page: filters.page.value || 1,
      }
    });
    return response.data;
  },

  async addAliasesForArtists(aliases){
    const response = await axios.post(`${api}/songs/artists/aliases`, {
      aliases: aliases
    });
    return response.data;
  },

  async addAliasesForSongs(aliases){
    const response = await axios.post(`${api}/songs/aliases`, {
      aliases: aliases
    });
    return response.data;
  },

  async getPersonStatements(id) {
    const response = await axios.get(`${api}/people/${id}/statements`);
    return response.data;
  },

  async deleteStatement(id) {
    const response = await axios.delete(`${api}/incoming-statements/${id}`)
    return response.data;
  },

  async restoreStatement(id) {
    const response = await axios.put(`${api}/incoming-statements/restore/${id}`)
    return response.data;
  },

  async updatePersonStatementPaidStatus(personId, id, status){
    const response = await axios.put(`${api}/people-statements/${personId}/${id}`, {
      paid: status
    });
    return response.data;
  },
  async refreshAirtables(){
    const response = await axios.get(`${api}/sync/airtables`);
    return response.data;
  },
  async uploadStatement(formData){
    const response = await axios.post(`${api}/incoming-statements/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response.data;

  },

  async deleteParser(parserId){
    const response = await axios.delete(`${api}/incoming-statements/parsers/${parserId}`);
    return response.data;
  },



  async validateUploadedStatement(parser, rows){
    const response = await axios.post(`${api}/incoming-statements/validate`, {
      id: parser.id,
      name: parser.userName,
      field_map: parser.field_map,
      field_regexp: parser.field_regexp,
      rows: rows
    })
    return response.data;
  },
  async getUser(){
    const response = await axios.get(`${api}/get-user`);
    return response.data;
  },
  async login(email, password){
    await axios.get(`${api}/csrf-cookie`)
    const response = await axios.post(`${api}/login`, {
      email: email,
      password: password
    });
    return response.data;
  },
  async logout(){
    const response = await axios.post(`${api}/logout`);
    return response.data;
  }
};
