<template lang="pug">
  v-speed-dial(v-model="fab" bottom right fab absolute fixed)
    template(v-slot:activator)
      v-btn(v-model="fab" fab color="primary")
        v-icon(v-if="fab")
          | mdi-close
        v-icon(v-else)
          | mdi-inbox-arrow-down-outline
    v-tooltip(left)
      template(v-slot:activator="{ on, attrs }")
        v-btn(fab small v-bind="attrs" v-on="on" color="primary" @click="add")
          v-icon mdi-plus
      span Add incoming statement
    v-tooltip(left)
      template(v-slot:activator="{ on, attrs }")
        v-btn(fab small v-bind="attrs" v-on="on" color="primary" @click="refresh")
          v-icon mdi-reload
      span Refresh Airtables
</template>

<script>
export default {
  props: {
  },
  data(){
    return {
      fab: false
    }
  },
  methods: {
    add(){
      this.$emit('add');
    },
    refresh(){
      this.$emit('refresh');
    }
  }
};
</script>

<style scoped lang="scss">
</style>
