<template lang="pug">
  v-dialog(:value="show" persistent width="500")
    v-card(color="primary"  dark)
      v-card-text Refreshing Airtables, please wait...
        v-progress-linear(indeterminate color="white")
</template>

<script>
import {mapState} from "vuex";
import api from '@/api';

export default {
  computed: {
    ...mapState({
      show: (state) => state.popups.refreshAirtables,
    }),
  },
  data() {
    return {
      status: null
    }
  },
  methods: {
    hide(){
      this.$store.commit('popups/hideRefreshPopup');
    },
    async refresh(){
      this.status = await api.refreshAirtables();
      await this.$store.dispatch('songs/getArtists');
      await this.$store.dispatch('incoming/getClients');
      await this.$store.dispatch('songs/getDefaultSongs');
      await this.$store.commit('popups/setRefreshStatus', this.status);
      this.hide();

      this.$emit('done', this.status);
    }
  },

  watch: {
    show(){
      if(this.show){
        this.status = this.refresh();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.headline{
  color: #FFFFFF;
}

.v-progress-linear{
  margin-top: 15px;
}
</style>
