import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Incoming",
    component: Home,
  },
  {
    path: "/outgoing",
    name: "Outgoing",
    component: () =>
      import(/* webpackChunkName: "outgoing" */ "../views/Outgoing.vue"),
  },
  {
    path: "/outgoing/:id",
    name: "Outgoing (filtered)",
    component: () =>
      import(/* webpackChunkName: "outgoing" */ "../views/Outgoing.vue"),
  },







  {
    path: "/songs",
    name: "Songs",
    component: () =>
      import(/* webpackChunkName: "songs" */ "../views/Songs.vue"),
  },
  {
    path: "/auth",
    name: "Authenticate",
    component: () =>
      import(/* webpackChunkName: "songs" */ "../views/Auth.vue"),
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
