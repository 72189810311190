<template lang="pug">
  .auth
    .logo
      img(src="@/assets/logo.png")
    v-text-field(v-model="user.email" outlined  label="Email address")
    v-text-field(type="password" outlined v-model="user.password" label="Password")
    .error(v-if="error") The credentials used are not valid.
    v-btn(color="primary"  @click="login" x-large) Login
</template>

<script>

export default {
  components: {
  },
  data(){
    return {
      user: {
        email: null,
        password: null
      },
      error: false,
    }
  },




  methods: {
    async login(){
      const response = await this.$store.dispatch('auth/login', this.user);
      this.error = !response.success;
      if(!this.error){
        this.$router.replace('/');
      }
    }
  }
};
</script>

<style lang="scss"  scoped>
.auth{
  background: #FFFFFF;
  border-radius: 10px;

  text-align: center;
  padding: 50px;

  .logo{
    margin-bottom: 35px;

    img{
      height: 90px;
    }
  }

  .v-btn{
    width: 100%;
    margin-top: 35px;
  }
}
</style>
