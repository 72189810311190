<template lang="pug">
  .page-title
    v-app-bar-nav-icon.d-lg-none(@click="showNavigation")
    h1 {{$route.name}}
</template>

<script>
export default {
  computed: {

  },
  methods: {
    showNavigation() {
      this.$store.commit('popups/showNavigation');
    }
  },
  watch: {
  }
};
</script>

<style scoped lang="scss">
@import "../styles/variables";

.page-title{
  background-color: $primary;
  color: #FFFFFF;

  padding: 15px;




  ::v-deep{

    .v-app-bar__nav-icon{
      color: #FFFFFF;
    }
  }
}



h1{
  font-size: 17px;
  display: inline-block;

  margin-left: 15px;
  position: relative;
  top: 3px;
}

</style>
