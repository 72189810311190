<template lang="pug">
</template>
<script>
import {mapState} from "vuex";
const sounds = {
  'incoming-added': 'imported.mp3',
  'paid': 'paid.mp3',
  'unpaid': 'unpaid.mp3',
  'delete': 'delete.mp3',
  'restore': 'restore.mp3'
}
const player = new Audio();

export default {
  computed: {
    ...mapState({
      soundEffect: state => state.popups.soundEffect
    })
  },
  methods: {
    playSound(sound){
      if(sound){
        const soundFiles = require.context('@/assets/', false, /\.mp3$/)
        player.src = soundFiles(`./${sound}`);
        player.play()
      }


      this.$store.commit('popups/playSoundEffect', null);
    }
  },
  watch: {
    soundEffect() {
      this.playSound(sounds[this.soundEffect]);
    }
  }
};
</script>

<style scoped lang="scss">
</style>
