import api from "../../api";

const state = {
  songs: [],
  fullSongs: {
    total_pages: 1,
    items: []
  },
  processedSongs: [],
  artists: [],
  defaultSongs: [],
  gettingDefaultSongs: false
};

const getters = {};
const actions = {
  async getSongs({ commit }) {
    const songs = await api.getSongs();
    commit("setSongs", songs);
  },
  async getFullSongs({ commit }, filters) {
    const songs = await api.getFullSongs(filters);
    commit("setFullSongs", songs);
  },
  async getArtists({ commit }) {
    const artists = await api.getArtists();
    commit("setArtists", artists);
    return artists;
  },
  async processSongs({ commit }, inputSongs) {
    const songs = await api.processSongs(inputSongs);
    commit("setProcessedSongs", songs);
    return songs.available;
  },
  async getPeopleForSong(store, songId) {
    return await api.getPeopleForSong(songId);
  },
  async getDefaultSongs({ commit, state }) {
    if(!state.gettingDefaultSongs && state.defaultSongs.length === 0){
      commit('setGettingDefaultSongs', true);
      const songs = await api.getSongsByName('');
      commit('setDefaultSongs', songs);
      commit('setGettingDefaultSongs', false);
    }
    return state.defaultSongs;
  },

};

const mutations = {
  setSongs(state, songs) {
    state.songs = songs;
  },
  setFullSongs(state, songs) {
    state.fullSongs = songs;
  },
  setArtists(state, artists) {
    state.artists = artists;
  },
  setProcessedSongs(state, processedSongs) {
    state.processedSongs = processedSongs;
  },
  setDefaultSongs(state, defaultSongs) {
    state.defaultSongs = defaultSongs;
  },
  setGettingDefaultSongs(state, gettingDefaultSongs) {
    state.gettingDefaultSongs = gettingDefaultSongs;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
