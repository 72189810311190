import api from "../../api";
const state = {
  people: {
    items: [],
    total_pages: 1
  },
  statements: [],
  filters: {
    name: '',
    statement: '',
    date: '',
    page: ''
  },
};

const getters = {};

const actions = {
  async getPeople({ commit, state }, filters) {
    if(filters){
      commit("setFilters", filters);
    }

    const people = await api.getPeople(state.filters);
    commit("setPeople", people);
  },

  async getPersonStatements(store, id) {
    return await api.getPersonStatements(id);
  },

  async updatePaidStatus({ dispatch}, statement) {
    await api.updatePersonStatementPaidStatus(statement.personId, statement.id, statement.paid);
    dispatch('getPeople');
  },

};
const mutations = {

  setPeople(state, people) {
    state.people = people;
  },

  setStatements(state, statements) {
    state.statements = statements;
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
