<template lang="pug">
  .song
    v-autocomplete(
      v-model="selectedSong"
      :items="songs"
      :loading="isLoading"
      :search-input.sync="search"
      item-text="name"
      item-value="id"
      @input="input"
      ref="autocomplete"
      label="Search songs"
      :filter="noFilter"
      no-data-text="No songs have been found for your query"
      dense
      hide-details
      return-object
      single-line
    )
      template(#no-data)
        v-list-item
          v-list-item-title(v-if="search && search.length > 2") No songs have been found for your query
          v-list-item-title(v-else) Please use at least 3 characters for your query
      template(#selection="data")
        v-list-item-avatar(rounded v-if="data.item.image")
          img(:src="data.item.image")
        v-list-item-avatar.placeholder(rounded v-else)
          v-icon mdi-music
        v-list-item-content
          v-list-item-title(v-html="data.item.name")
          v-list-item-subtitle(v-html="data.item.artist")
      template(#item="data")
        v-list-item-avatar(rounded v-if="data.item.image")
          img(:src="data.item.image")
        v-list-item-avatar.placeholder(rounded v-else)
          v-icon mdi-music
        v-list-item-content
          v-list-item-title(v-html="data.item.name")
          v-list-item-subtitle(v-html="data.item.artist")
      template(#append-outer)
        .player(v-if="selectedSong && selectedSong.preview")
          v-btn(fab x-small v-if="!playing" @click="playPreview")
            v-icon( ) mdi-play
          v-btn(fab x-small v-else @click="stopPlayer")
            v-icon( ) mdi-stop
        .player-placeholder(v-else)


</template>

<script>
import api from '../api'
import {mapState} from 'vuex';

export default {
  props: {
    inputSong: {
      type: Object,
      default: () => ({id: null})
    }
  },
  computed: {
    songs(){
      let songs = this.filteredSongs;
      if(!this.search){
        songs = this.defaultSongs;
      }
      if(this.selectedSong && this.selectedSong.name){
        return [this.selectedSong, ...songs];
      }
      return songs;
    },
    ...mapState({
        defaultSongs: state => state.songs.defaultSongs
      }
    )
  },
  data() {
    return {
      playing: false,
      player: new Audio(),

      isLoading: false,
      search: null,
      filteredSongs: [],
      selectedSong: false,
      searchPause: null
    }
  },
  methods: {
    playPreview(){
      this.player.src = this.selectedSong.preview;
      this.player.play()

      this.playing = true;
    },
    input(){
      this.stopPlayer();
      this.search = '';
      this.$refs.autocomplete.$el.querySelector('.v-select__selections input').blur();
    },
    noFilter(){
      return true;
    },
    stopPlayer(){
      this.player.pause();
      this.playing = false;
    }
  },
  watch: {
    async search () {

      // Items have already been requested
      if (this.isLoading) return


      if(this.searchPause){
        clearTimeout(this.searchPause);
      }
      this.searchPause = setTimeout(async () => {
        if(this.search && this.search.length <=2){
          this.filteredSongs = [];
          return;
        }

        this.isLoading = true

        this.filteredSongs = await api.getSongsByName(this.search);
        this.isLoading = false

      }, 500);

    },

    selectedSong(){
      if(this.selectedSong && this.selectedSong.id){
        this.$emit('input', this.selectedSong.id)
        this.$emit('object', this.selectedSong)
        this.inputSong.id = this.selectedSong.id;
      }
    }

  },
  async mounted() {
    if(this.inputSong && this.inputSong.id){
      // this.selectedSong = this.inputSong;
    }

    this.$store.dispatch('songs/getDefaultSongs');
  }
};
</script>

<style scoped lang="scss">
@import "../styles/variables";

.player-placeholder{
  width: 40px;
}




.v-list-item__content{
  padding: 0px;
  margin: 5px 0px;
}

::v-deep .v-select .v-select__selections {
  min-height: 35px;
  overflow: hidden;


  input{
    position: absolute;
    top: 0px;

    opacity: 0;
    transition: opacity 0.25s;

    height: 100%;
    max-height: none;
    min-height: 35px;
    background-color: transparent;
    font-size: 21px;

    text-shadow: 0px 0px 2px #FFFFFF;
    border-radius: 10px;
  }

  .v-list-item__content + input{
    left: 50px;
  }

  input:focus{

    backdrop-filter: blur(5px);
    opacity: 1;

    & ~ div{
      opacity: 0.5;
    }
  }
}

.v-avatar.placeholder{
  background: rgba($primary, 0.35);
  color: #FFFFFF;

  .v-icon{
    color: #FFFFFF;
  }
}

.v-list-item__title{
  text-align: left;
  font-size: 15px;
}

.v-list-item__subtitle{
  text-align: left;
  font-size: 12px;
}

::v-deep .v-list-item__title{
  text-align: left;
}

.v-list-item__subtitle{
  text-align: left;
}

.player{
  position: relative;
  top: 7px;
}
</style>
