<template lang="pug">
  .home(@drop="fileUploaded" @dragover="fileHover")
    v-container
      PageTitle
      div.incoming-page(:class="{hover: fileHovering}")
        .dragover-holder(@dragleave="stopFileHover")
        IncomingTable
        Fab(@add="showUploadPopup" @refresh="showRefreshPopup")
        StatementInputPopup
        StatementUploadPopup
</template>


<script>

import IncomingTable from "@/components/IncomingTable";
import Fab from "@/components/Fab";
import StatementInputPopup from "@/components/StatementInputPopup";
import StatementUploadPopup from "@/components/StatementUploadPopup";
import RefreshPopup from "@/components/RefreshPopup";
import PageTitle from "../components/PageTitle";

export default {
  name: "Home",
  components: {
    PageTitle,
    RefreshPopup,
    StatementInputPopup,
    Fab,
    IncomingTable,
    StatementUploadPopup,
  },
  methods: {
    showUploadPopup(){
      this.$store.commit('popups/showIncomingStatementUploadPopup')
    },

    showRefreshPopup(){
      this.$store.commit('popups/showRefreshPopup')
    },

    fileUploaded(ev) {

      ev.preventDefault();
      let file = null;

      if (ev.dataTransfer.items) {
        if(ev.dataTransfer.items[0] && ev.dataTransfer.items[0].kind === 'file'){
          file = ev.dataTransfer.items[0].getAsFile();
        }
      } else {
        if(ev.dataTransfer.files[0]){
          file = ev.dataTransfer.files[0];
        }
      }

      this.fileHovering = false;
      this.$store.commit('popups/setStatementUploadFile', file);
      this.$store.commit('popups/showIncomingStatementUploadPopup')

    },
    fileHover(ev) {
      this.fileHovering = true;
      ev.preventDefault();
    },
    stopFileHover(ev) {
      this.fileHovering = false;
      ev.preventDefault();
    },
  },

  computed: {
  },
  data(){
    return {
      fileHovering: false
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/variables";

.incoming-page{
  position: relative;


  &.hover{
    .dragover-holder{
      width: 100%;
      height: 100%;

      background-color: rgba($primary, 0.25);


      position: fixed;
      top: 0px;
      left: 0px;
    }
  }
}
</style>
