import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const theme = {
  primary: '#0395E8',
  secondary: '#D9F3FF',
  accent: '#3B86B0',
  info: '#00EAE3',
  success: '#4AAF50',
  warning: '#FB8E00',
  error: '#FF5252',
}

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: theme,
    },
  },
});
