const state = {
  incomingStatement: false,
  refreshAirtables: false,
  incomingStatementUpload: false,
  statementUploadFile: null,
  alerts: {
    incoming: [],
    peopleStatements: []
  },
  soundEffect: null,
  refreshAirtablesStatus: null,
  showNavigation: null,
};

const getters = {};
const actions = {
};

const mutations = {
  showIncomingStatementsPopup(state) {
    state.incomingStatement = true;
  },
  hideIncomingStatementsPopup(state) {
    state.incomingStatement = false;
  },
  addIncomingAlert(state, alert) {
    alert.visible = true;
    state.alerts.incoming = [...state.alerts.incoming, alert];
  },
  addPeopleStatementsAlert(state, alert) {
    alert.visible = true;
    state.alerts.peopleStatements = [...state.alerts.peopleStatements, alert];
  },
  playSoundEffect(state, soundEffect){
    state.soundEffect = soundEffect;
  },
  showRefreshPopup(state) {
    state.refreshAirtables = true;
  },
  hideRefreshPopup(state) {
    state.refreshAirtables = false;
  },
  setStatementUploadFile(state, file) {
    state.statementUploadFile = file;
  },
  setRefreshStatus(state, status) {
    state.refreshAirtablesStatus = status;
  },
  showIncomingStatementUploadPopup(state) {
    state.incomingStatementUpload = true;
  },
  hideIncomingStatementUploadPopup(state) {
    state.incomingStatementUpload = false;
  },
  showNavigation(state) {
    state.showNavigation = true;
  },
  hideNavigation(state) {
    state.showNavigation = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
