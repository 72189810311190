import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import songs from "./modules/songs";
import incoming from "./modules/incoming";
import outgoing from "./modules/outgoing";
import popups from "./modules/popups";
import auth from './modules/auth';

export default new Vuex.Store({
  modules: {
    songs,
    incoming,
    outgoing,
    popups,
    auth
  },
});
