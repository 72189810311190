<template lang="pug">
  v-app#app
    .authenticated(v-if="showApp")
      SoundEffects
      v-navigation-drawer(app dark :src="background" :class="{incoming: $route.path === '/', outgoing: $route.path === '/outgoing'}" :value="showNavigation" @input="hideNavigation")
        v-list
          v-list-item.mb-0.justify-space-between.pl-3
            v-list-item-icon.logout(@click="logout")
              v-tooltip(right)
                template(v-slot:activator="{ on, attrs }")
                  v-icon(v-bind="attrs" v-on="on") mdi-logout
                | Log out
            v-list-item-content.pl-2
              v-list-item-title(v-if="user")
                | {{user.name}}
          v-divider.mx-3.mb-2
          v-list-item(@click="navigate('/')" :class="{active: $route.path === '/'}")
            v-list-item-icon
              v-icon mdi-inbox-arrow-down-outline
            v-list-item-content
              v-list-item-title Incoming
          v-list-item(@click="navigate('/outgoing')" :class="{active: $route.path === '/outgoing'}")
            v-list-item-icon
              v-icon mdi-inbox-arrow-up-outline
            v-list-item-content
              v-list-item-title Outgoing
          v-list-item(@click="navigate('/songs')" :class="{active: $route.path === '/songs'}")
            v-list-item-icon
              v-icon mdi-inbox-arrow-up-outline
            v-list-item-content
              v-list-item-title Songs
      v-main
        v-container
          router-view
          footer
            a(href="https://wp.me/P9vd38-19P" target="_blank") Support
            a(href="https://airtable.com/shrmAcwwPeAil79Ce" target="_blank") Feature requests

      Refresh(v-if="!showFab")
      RefreshPopup
      v-snackbar(:value="showAirtablesStatus" color="primary" v-if="showAirtablesStatus" )
        div
          strong Airtables refresh has been done.
        br
        div Songs: {{refreshStatus.status.songs.added}} added, {{refreshStatus.status.songs.updated}} updated
        div Artists: {{refreshStatus.status.artists.added}} added, {{refreshStatus.status.artists.updated}} updated
        div Rights-holders: {{refreshStatus.status.people.added}} added, {{refreshStatus.status.people.updated}} updated
        div Royalties: {{refreshStatus.status.royalties.added}} added, {{refreshStatus.status.royalties.updated}} updated
        div Fees: {{refreshStatus.status.fees.added}} added, {{refreshStatus.status.fees.updated}} updated
    .authenticate(v-if="showAuth")
      v-row(justify="center" align="center" no-gutters)
        v-col(cols="12" sm="6" md="3" align-self="center")
          router-view
    .loading-app(v-if="loading")
      v-row(justify="center" align="center" no-gutters)
        v-col(cols="12" sm="6" md="3" align-self="center")
          v-progress-circular(indeterminate :size="150" color="primary"  )
</template>

<script>
import SoundEffects from "@/components/SoundEffects";

import { mapState, mapGetters } from 'vuex';
import Auth from "./views/Auth";
import Refresh from "@/components/Refresh";
import RefreshPopup from "@/components/RefreshPopup";

export default {
  components: {
    Auth,
    SoundEffects,

    Refresh,
    RefreshPopup
  },
  computed: {
    showAirtablesStatus() {
      return this.refreshStatus && this.refreshStatus.done;
    },
    showFab(){
      return this.$route.path === '/';
    },
    showAuth(){
      return this.$route.path === '/auth' && !this.loading;
    },
    showApp(){
      return this.$route.path !== '/auth' && !this.loading;
    },
    ...mapState({
      user: state => state.auth.user,
      refreshStatus: state => state.popups.refreshAirtablesStatus,

      showNavigation: state => state.popups.showNavigation
    }),
    ...mapGetters({
      loggedIn: 'auth/isLoggedIn'
    })
  },
  data(){
    return {
      drawer: false,
      fab: false,
      background: null,
      loading: true,
      backgrounds: {
        '/': '1.jpg',
        '/outgoing': '2.jpg',
        '/songs': '3.jpg',
      },
    }
  },
  methods: {
    navigate(route){
      this.$router.push(route);
      this.drawer = false;
    },
    async logout(){
      await this.$store.dispatch('auth/logout');
      window.location = '/';
    },
    setBackground(backgrounds){
      const images = require.context('@/assets/', false, /\.jpg$/)
      const id = backgrounds[this.$route.path];
      if(id){
        this.background = images(`./${id}`)
      }
    },
    async getData(){
      await this.$store.dispatch("songs/getArtists");
      await this.$store.dispatch("incoming/getClients");
    },
    hideNavigation(show) {
      if(!show){
        this.$store.commit('popups/hideNavigation');
      }
    }
  },
  async mounted(){
    await this.$store.dispatch('auth/getUser')
    if(!this.loggedIn){
      this.$router.replace('/auth');
    }
    this.loading = false;
  },
  watch:{
    '$route':{
      immediate: true,
      handler: function() {
        this.setBackground(this.backgrounds);

        let title = ['Soundcuts'];
        if(this.$route.name){
          title[title.length] = this.$route.name;
        }
        document.title = title.join(' | ');
      }
    },
    loggedIn: {
      immediate: true,
      handler: function(){
        if(this.loggedIn){
          this.getData()
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import 'styles/variables';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  min-width: 768px;
}

.v-navigation-drawer{

  .v-image{
    position: relative;
    filter: brightness(0.75);

    .v-image__image{
      filter: grayscale(0.5);
    }
    &:after{
      content: '';
      width: 100%;


      height: 100%;

      position: absolute;
      top: 0px;
      left: 0px;
    }
  }

  &.incoming{
    .v-image{

      &:after{
        background-color: rgba($primary, 0.35);
      }

    }
  }

  &.outgoing{
    .v-image{

      &:after{
        background-color: rgba($warning, 0.25);
      }
    }
  }
}
.logout{
  cursor: pointer;
}

.v-main .v-pagination{
  margin-top: 25px;
}

.refresh-button{
  bottom: 16px !important;
}

.authenticate{
  height: 100vh;
  background-image: url("~@/assets/auth-background.jpg");
  overflow: hidden;

  .row{
    min-height: 100vh;
  }
}
.loading-app{
  text-align: center;

  .row{
    min-height: 100vh;
  }
}

.v-list-item.active{
  background-color: rgba($secondary, 0.35);
}

.v-main footer{
  width: 100%;

  text-align: center;

  margin-top: 35px;

  &.theme--light{
    background-color: transparent;
  }

  a{
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
  }
}

</style>
