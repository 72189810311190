<template lang="pug">
  div(v-if="statement")
    h2.form-title Statement details
    v-row
      v-col
        v-text-field(label="Statement name" v-model="statement.name")
    v-row
      v-col(cols="6" align-self="end")
        v-autocomplete( v-model="statement.client" :items="clients" label="Select client" item-text="name" item-value="id" @input="clientName")
          template(#selection="data")
            v-list-item-content
              v-list-item-title(v-html="data.item.name")
      v-col(align-self="end")
        Datepicker(v-model="statement.date")

    h2.form-title Songs
    .songs
      v-container
        .song(v-for="(inputSong, index) in statement.inputSongs" :key="index")
          v-row
            v-col(cols="8")
              SongInput(:input-song="inputSong" :hidden-songs="selectedSongs")
            v-col(cols="3" align-self="end")
              v-spacer
              v-text-field.amount-input(label="Amount" type="number" placeholder="0.00"  prefix="$" v-model="inputSong.value")
            v-col(cols="1" align-self="end")
              v-tooltip(top)
                template(v-slot:activator="{ on, attrs }")
                  v-btn(@click="deleteSong(index)" small fab v-bind="attrs" v-on="on" color="error")
                    v-icon mdi-delete
                | Delete song from list
        .song-add
          v-tooltip(top)
            template(v-slot:activator="{ on, attrs }")
              v-btn(@click="addSong" small fab v-bind="attrs" v-on="on" color="primary")
                v-icon mdi-plus
            | Add song
</template>

<script>
import {mapState} from "vuex";
import SongInput from "@/components/SongInput";
import Datepicker from "./Datepicker";
export default {
  props: ['statement'],

  data(){
    return {
      fab: false,
    }
  },



  components: {
    Datepicker,
    SongInput
  },
  computed: {
    selectedSongs(){
      return this.statement.inputSongs.map(song => song.id);
    },
    ...mapState({
      clients: (state) => state.incoming.clients,
      songs: (state) => state.songs.songs,
    }),
  },
  methods: {
    addSong(){
      this.$emit('add-song');
    },
    deleteSong(index){
      this.$emit('delete-song', index);
    },
    clientName(id){
      const client = this.clients.find(client => client.id === id);
      this.statement.clientName = client.name;
    },
    playPreview(preview){
      if(preview){
        alert(preview)
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("songs/getSongs");
  }
};
</script>

<style scoped lang="scss">
  @import "../styles/variables";
  ::v-deep{

    .v-list-item__title{
      text-align: left;
    }

    .v-list-item__subtitle{
      text-align: left;
    }

    .v-input{
    }

    .songs {

      .song{

        .v-input.amount-input{
          margin-top: -5px;

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            display: none;
            -webkit-appearance: none;
            margin: 0;
          }

          .v-text-field__details{
            display: none;
          }
        }
      }
    }
  }

  .song-add{

    text-align: center;

    margin-top: 25px;

    &::v-deep{

      .v-btn{
        margin-left: 12px;
      }
    }
  }

  h2.form-title{
    text-align: left;
    margin-bottom: 15px;
    margin-top: 25px;

    border-bottom: 1px solid --v-primary-base;
    padding-bottom: 10px;
  }
</style>
