<template lang="pug">
  v-tooltip(left)
    template(v-slot:activator="{ on, attrs }")
      v-btn.refresh-button(fab v-bind="attrs" v-on="on" color="primary" @click="refresh" fixed bottom right)
        v-icon mdi-reload
    span Refresh Airtables
</template>

<script>
export default {
  props: {
  },
  methods: {
    refresh(){
      this.$store.commit('popups/showRefreshPopup')
    }
  }
};
</script>

<style scoped lang="scss">
</style>
