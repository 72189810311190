<template lang="pug">
  v-dialog(:value="show" width="960" @input="hide" persistent)
    v-card
      v-card-title.headline.primary
        | Upload incoming statement
        v-spacer
        v-btn.close(icon @click="hide")
          v-icon(color="white") mdi-close
      v-progress-linear.loading(v-show="loading" indeterminate)
      v-card-text

        .steps(:class="{loading: loading}")
          .step.step-1(v-show="step === 0")
            .drop-file(@drop="fileUploaded" @dragover="fileHover" @dragleave="stopFileHover" :class="{hover: fileHovering}")
              .select-file(v-show="!fileName")
                .select-text Please drag a file from your computer
                  strong - or -
                  | Select one using the button below
                v-btn(color="primary" @click="selectFile") Browse
              .file-uploading(v-show="fileName")
                .uploading-text Uploading&nbsp;
                  strong {{fileName}}
                v-progress-circular(indeterminate color="primary"    size="150" width="5")
              input.file-upload-input(type="file" @change="fileSelected"  ref="fileInput")

          .step.step-2(v-show="step === 1" v-if="uploadStatus && uploadStatus.rows")
            .parser-options



              v-select.parser(:items="parsers" item-text="name" item-value="id" v-model="parserId" label="Select a preset for the current statement type" ref="parserSelect")
                template(#item="{ index, item}")
                  v-list-item.parser-item {{item.name}}
                  v-spacer
                  v-list-item-action(v-show="item.id")
                    v-tooltip(top) Duplicate preset
                      template(#activator="{ on, attrs }")
                        v-btn(icon @click.stop.prevent="duplicateParser(item)" color="primary" title="duplicate" v-bind="attrs" v-on="on")
                          v-icon mdi-content-duplicate
                  v-list-item-action(v-show="item.id")
                    v-tooltip(top) Delete preset
                      template(#activator="{ on, attrs }")
                        v-btn(icon @click.stop.prevent="showDeleteParser(item)" color="error" v-bind="attrs" v-on="on")
                          v-icon mdi-delete
              v-text-field.parser-name(v-model="parserName" label="Preset name" :disabled="parserId !== null" required ref="parserName")

              v-expand-transition
                .parser-delete(v-if="showDelete && deleteParser && deleteParser.id")
                  .delete-holder
                    p Deleting parser {{deleteParser.name}}. Are you sure?
                    v-btn(text dense  @click="hideParserDelete") No
                    v-btn(text dense  color="error" @click="processParserDelete") Yes

            FieldMapper(:parser="parser" :all-rows="uploadStatus.rows"  :showPatterns="showPatterns" @regexpError="regexpError = $event")


            .validations
              .validation
                v-icon(color="error" v-if="validationErrors.includes('parser')") mdi-close
                v-icon(color="success" v-else) mdi-check
                | The preset has a name
              .validation(v-if="!skipArtists")
                v-icon(color="success") mdi-check
                | Artist field is mapped
              .validation(v-else)
                v-icon(color="warning") mdi-alert-circle
                | Artist unmapped, skipping aliases
              .validation
                v-icon(color="error" v-if="validationErrors.includes('name')") mdi-close
                v-icon(color="success" v-else) mdi-check
                | Song field is mapped
              .validation
                v-icon(color="error" v-if="validationErrors.includes('value')") mdi-close
                v-icon(color="success" v-else) mdi-check
                | Value field is mapped
              .validation
                v-icon(color="error" v-if="validationErrors.includes('regex')") mdi-close
                v-icon(color="success" v-else) mdi-check
                | No errors in the text patterns


            v-checkbox(v-model="showPatterns" label="Show patterns for text extraction (advanced)")


          .step.step-new-items(v-show="step === 2" v-if="fileValidation")
            .loading-text.loading-artists(v-show="showArtists && loadingArtists")
              .artists-loading Please wait, loading artists...
              v-progress-circular(indeterminate color="primary"    size="150" width="5")
            .artists(v-show="showArtists && !loadingArtists")
              .alias-description The following artists were not found in the database.
              .alias-actions Please add an entry for them in Airtables and hit the refresh button, or select an existing artist as an alias.
              .import-fields.artist-fields
                .field-name Statement artist
                .field-select Existing artists
              .import-items.artist(v-for="(artist, index) in fileValidation.artists")
                .item-name.artist-alias {{artist}}
                .item-select.artist-list
                  v-combobox.parser(:items="artists" item-text="name" item-value="id" @input="addArtistAlias($event, artist, index)" label="Add as an alias for this artist" return-object)
            .songs(v-show="showSongs")
              .song-alias-description(v-show="!skipArtists")
                .alias-description The following songs were not found in the database.
                .alias-actions Please add an entry for them in Airtables and hit the refresh button, or select an existing artist as an alias.
              .song-manual-description(v-show="skipArtists")
                .alias-description Artist data is unavailable
                .alias-actions Please manually select each matching song from the list
              .import-fields.song-fields
                .field-name Statement song
                .field-select Existing songs
              .import-items.song(v-for="(song, index) in fileValidation.songs" :class="{even: index%2 === 0}")
                .item-name.song-alias
                  .song-name {{song.name}}
                  .song-artist(v-if="song.artist") {{song.artist.name}}
                  .song-artist(v-else) N/A
                .item-select.songs-list
                  SongInput(@object="addSong($event, song, index)" v-if="skipArtists")
                  v-combobox.parser(:items="getSongsForArtist(song.artist.id)" item-text="name" item-value="id" v-else @input="addSongAlias($event, song, index)" :label="`Add as an alias for an existing ${song.artist.name} song`" return-object)
          .step.step-save(v-show="step === 3")
            .save-description The file has been successfully processed
            .save-actions Please add your statement details below and click the finish button.
            v-row
              v-col
                v-text-field(label="Statement name" v-model="statement.name")
            v-row
              v-col(cols="6" align-self="end")
                Datepicker(v-model="statement.date")
              v-col(align-self="end")
                v-autocomplete.statement-client( dense v-model="statement.client" :items="clients" label="Select client" item-text="name" return-object)
                  template(#selection="data")
                    v-list-item-content
                      v-list-item-title(v-html="data.item.name")

            .validations
              .validation
                v-icon(color="error" v-if="saveErrors.includes('name')") mdi-close
                v-icon(color="success" v-else) mdi-check
                | The statement has a name
              .validation
                v-icon(color="error" v-if="saveErrors.includes('date')") mdi-close
                v-icon(color="success" v-else) mdi-check
                | The statement has a date
              .validation
                v-icon(color="error" v-if="saveErrors.includes('client')") mdi-close
                v-icon(color="success" v-else) mdi-check
                | The statement has a client

      v-divider
      v-card-actions
        v-tooltip(top v-if="step >= 2") Refresh Airtables
          template(#activator="{ on, attrs }")
            v-btn.refresh-airtables(fab small  @click="refreshAirtables" v-bind="attrs" v-on="on" :disabled="loading")
              v-icon mdi-refresh
        v-btn(@click="back" v-if="step > 0" :disabled="loading") Back
        v-btn(@click="manualInput" v-if="step === 0") Manual input
        v-spacer
        v-btn(color="primary" @click="uploadFile" v-show="step === 0" :disabled="!allowNext || loading")
          | Upload statement
        v-btn(color="primary" @click="validateFile" v-show="step === 1" :disabled="!allowValidation || loading")
          | Verify data
        v-btn(color="primary" @click="addAliases" v-show="showAliases" :disabled="!allowAddAliases")
          | Add aliases
        v-btn(color="primary" @click="skipSongAliases" v-show="showSkipSongAliases" :disabled="!allowSkipSongAliases")
          | Apply assignments
        v-btn(color="primary" @click="saveStatement" v-show="step === 3" :disabled="!allowSave || loading")
          | Finish
</template>

<script>
import {mapState} from "vuex";
import FieldMapper from './FieldMapper'

import SongInput from './SongInput'

import api from '@/api';
import Datepicker from "./Datepicker";

export default {
  components: {
    Datepicker,
    FieldMapper,
    SongInput
  },
  computed: {
    ...mapState({
      show: (state) => state.popups.incomingStatementUpload,
      storeFile: (state) => state.popups.statementUploadFile,
      parsers: (state) => state.incoming.parsers,
      artists: (state) => state.songs.artists,
      clients: (state) => state.incoming.clients,
      refresh: (state) => state.popups.refreshAirtables,
    }),

    songs(){
      let songs = [];
      if(this.fileValidation){
        songs = this.fileValidation.matched_songs;
      }

      return songs;
    },

    showArtists() {
      let show = false;

      if(this.fileValidation && this.fileValidation.artists.length){
        show = true;
      }

      if(this.skipArtists){
        show = false;
      }

      return show;
    },

    showSongs() {
      let show = false;
      if(this.fileValidation && !this.showArtists && this.fileValidation.songs.length){
        show = true;
      }

      return show;
    },

    showAliases(){
      return this.step === 2 && (!this.skipArtists && !this.showSkipSongAliases);
    },

    showSkipSongAliases(){
      return this.step === 2 && this.skipArtists;
    },




    allowAddAliases(){
      let show = true;

      if(this.step !== 2){
        show = false;
      }



      if(this.loading){
        show = false;
      }


      if(this.showArtists){
        if(this.artistsAliases.filter(artist => artist).length < this.fileValidation.artists.length){
          console.log(this.artistsAliases.filter(artist => artist).length)
          show = false;
        }
      }
      if(this.showSongs){
        if(this.songsAliases.filter(song => song).length < this.fileValidation.songs.length){
          show = false;
        }
      }

      return show;
    },
    allowSkipSongAliases(){
      return this.fileValidation
        && this.fileValidation.songs.length === this.statementSongs.length
        && !this.loading;
    },

    allowValidation(){
      let allow = true;

      if(this.parser){

        if(!this.parserName){
          allow = false;
        }
        if(isNaN(this.parser.field_map.name)){
          allow = false;
        }
        if(isNaN(this.parser.field_map.value)){
          allow = false;
        }
        if(this.regexpError){
          allow = false;
        }

      }else{
        allow = false;
      }

      return allow;
    },

    validationErrors(){
      let errors = [];

      if(!this.parser || !this.parser.field_map){
        return errors;
      }

      if(!this.parserName){
        errors.push('parser')
      }
      if(isNaN(this.parser.field_map.artist) || this.parser.field_map.artist === null){
        errors.push('artist')
      }
      if(isNaN(this.parser.field_map.name)){
        errors.push('name')
      }
      if(isNaN(this.parser.field_map.value)){
        errors.push('value')
      }
      if(this.regexpError){
        errors.push('regex')
      }
      return errors;
    },
    allowSave(){
      let allow = true;
      if(!this.statement.name){
        allow = false;
      }
      if(!this.statement.client){
        allow = false;
      }

      return allow;
    },

    saveErrors(){
      let errors = [];

      if(!this.statement.name){
        errors.push('name')
      }
      if(!this.statement.client){
        errors.push('client')
      }
      if(!this.statement.date || !this.statement.date.length === 0){
        errors.push('date')
      }
      return errors;
    }

  },

  methods: {
    hide(){
      this.$store.commit('popups/hideIncomingStatementUploadPopup');

      this.resetFields();
    },
    getSongsForArtist(artist){
      return this.songs.filter(song => song.artist_id === artist);
    },
    addSong(song, statementSong, index){
      this.statementSongsIterator.set(`${index}`, {
          ...song,
          value: statementSong.value
        }
      )
      this.statementSongs = Array.from(this.statementSongsIterator.values())
    },
    addSongAlias(song, statementSong, index){
      this.$set(this.songsAliases, index, {
        id: song.id,
        alias: statementSong.name,
        value: statementSong.value
      })
    },
    addArtistAlias(artist, statementArtist, index){
      this.$set(this.artistsAliases, index, {
        id: artist.id,
        alias: statementArtist,
      })
    },
    fileSelected(){
      const file = this.$refs.fileInput.files[0];
      this.uploadedFile = file;
      this.uploadFile();
    },
    fileUploaded(ev) {

      ev.preventDefault();
      let file = null;

      if (ev.dataTransfer.items) {
        if(ev.dataTransfer.items[0] && ev.dataTransfer.items[0].kind === 'file'){
          file = ev.dataTransfer.items[0].getAsFile();
        }
      } else {
        if(ev.dataTransfer.files[0]){
          file = ev.dataTransfer.files[0];
        }
      }

      this.fileHovering = false;
      this.uploadedFile = file;
      this.uploadFile();
    },
    fileHover(ev) {
      this.fileHovering = true;
      ev.preventDefault();
    },
    stopFileHover(ev) {
      this.fileHovering = false;
      ev.preventDefault();
    },
    manualInput(){
      this.hide();
      this.$store.commit('popups/showIncomingStatementsPopup');
    },
    selectFile(){
      this.$refs.fileInput.click();
    },

    skipSongAliases(){
      this.next();
    },

    async addAliases(){
      if(this.showArtists){
        await api.addAliasesForArtists(this.artistsAliases);
        this.artistsAliases = []
      }
      if(this.showSongs){
        await api.addAliasesForSongs(this.songsAliases);
        this.songsAliases = [];
      }
      this.validateFile();
    },
    async saveStatement(){
      await this.$store.dispatch("incoming/addStatement", {
        name: this.statement.name,
        clientId: this.statement.client.id,
        songs: this.statementSongs,
        date: this.statement.date
      });
      this.$store.commit('popups/addIncomingAlert', {
        name: this.statement.name,
        type: 'incoming-added',
        client: this.statement.client.name
      })

      this.hide();
    },
    async refreshAirtables(){
      await this.$store.commit('popups/showRefreshPopup')
      if(this.step === 2){
        this.validateFile();
      }
    },
    async validateFile(){
      this.loading = true;
      if(this.uploadStatus){
        this.parser.userName = this.parserName
        this.fileValidation = await api.validateUploadedStatement(this.parser, this.uploadStatus.rows);
        this.statementSongs = this.fileValidation.available_songs;
        let next = false;

        if(this.step === 1){
          this.next();
          next = true;
        }

        if(!this.showArtists && !this.showSongs){
          this.next();
          next = true;
        }

        if(!next){
          this.loading = false;
        }
      }else{
        this.fileValidation = null;
      }
    },
    async uploadFile(){
      const file = this.uploadedFile;
      if(!file){
        return false;
      }
      console.log('uploading');
      this.loading = true;
      if(file){
        const formData = new FormData();
        formData.append( 'statement', file);
        this.uploadStatus = await api.uploadStatement(formData);
        this.$store.commit('popups/setStatementUploadFile', null);
        this.$refs.fileInput.value = null;
        this.allowNext = true;
        this.setFields();
        this.next();
      }
    },

    setFields(){
      // const fields = this.uploadStatus.rows[0].length;
      // for(let i = 0; i < fields; i++){
      //   this.matchedFields[i] = 'none';
      // }
    },
    back(){
      this.step = this.step - 1
    },
    next(){
      this.loading = true;
      setTimeout(() => {
        this.step = this.step + 1
        this.loading = false;
      }, 500)
    },

    setParserName(){
      this.parserName = this.parser.name;
      if(this.parser.id === null && !this.parser.duplicate){
        this.parserName = '';
      }
    },

    showDeleteParser(parser){
      this.deleteParser = parser;
      this.showDelete = true;

      this.$refs.parserSelect.blur();
    },

    hideParserDelete(){
      this.deleteParser = null;
      this.showDelete = false;
    },

    processParserDelete(){
      if(this.deleteParser && this.deleteParser.id){
        this.$store.dispatch('incoming/deleteParser', this.deleteParser.id);
      }

      this.hideParserDelete();
    },

    async duplicateParser(parser){
      const newParser = JSON.parse(JSON.stringify(parser));
      newParser.name = `${newParser.name} (duplicate)`;
      newParser.id = null;

      newParser.duplicate = true;

      this.parserId = null;
      this.$refs.parserSelect.blur();
      await this.$nextTick();

      this.setParser(newParser);

    },

    setParser(parser){
      this.parser = parser || null;
      if(this.parser){
        this.matchedFields = this.parser.field_map;
      }

      this.setParserName();
      this.hideParserDelete();
    },

    setupUploadedFile(){
      if(this.uploadedFile){
        this.fileName = this.uploadedFile.name;
      }else{
        this.allowNext = false;
      }
    },



    resetFields(){
      this.step = 0;
      this.uploadedFile = null;

      this.parserId = null;
      this.fileName = null;
      this.uploadStatus = null;
      this.fileValidation = null;
      this.skipArtists = false;
      this.artistsAliases = [];
      this.songsAliases = [];
    }

  },
  data() {
    return {
      uploadStatus: [],
      parserId: null,
      parser: null,
      matchedFields: [],




      selectedRows: {},
      step: 0,
      loading: false,
      showPatterns: false,
      parserName: '',
      fileHovering: false,
      fileName: '',
      uploadedFile: null,
      allowNext: false,
      fileValidation: null,







      artistsAliases: [],
      songsAliases: [],
      loadingArtists: false,
      regexpError: false,
      statement: {
        date: []
      },
      showDelete: false,
      deleteParser: null,
      skipArtists: false,
      statementSongs: [],
      statementSongsIterator: new Map(),
    }
  },
  async mounted() {
    if(!this.parser){
      await this.$store.dispatch('incoming/getIncomingStatementParsers');
      this.parser = this.parsers[0];

      this.setParserName();
    }

  },

  watch: {
    parserId(){
      const parser = this.parsers.filter(parser => parser && (parser.id === this.parserId));
      this.setParser(parser[0]);
    },
    storeFile(){
      if(this.storeFile){
        this.uploadedFile = this.storeFile;
        this.setupUploadedFile();

        this.uploadFile();
      }

    },
    uploadedFile(){
      this.setupUploadedFile();
    },
    validationErrors(){
      this.skipArtists = this.validationErrors.includes('artist');
    },

    step(){
      if(this.step === 0){
        this.resetFields();
      }
    },

    refresh(){
      if(!this.refresh){
        // this.validateFile();
      }
    },
    async showArtists(){
    },
  }
};
</script>

<style scoped lang="scss">
@import "../styles/variables";

.headline{
  color: #FFFFFF;
}
.drop-file{
  width: 100%;
  height: 350px;


  border: 3px dashed $primary;
  border-radius: 15px;

  color: $gray;

  &.hover{
    background-color: rgba($primary, 0.15);
  }
}
.steps{
  margin-top: 25px;

  &.loading{
    opacity: 0.5;
    pointer-events: none;
  }
}
.step{
  padding: 25px;
  text-align: left;

  &.step-1{
    text-align: center;
  }

  &.step-save{

    ::v-deep .v-text-field__details{
      display: none;
    }

    ::v-deep .statement-client{
      position: relative;
      top: -4px;
    }
  }

}
.loader{
}
.file-upload-input{
  width: 0px;
  height: 0px;

  position: absolute;
}

.select-text{
  font-size: 21px;

  margin-bottom: 35px;

  margin-top: 90px;

  strong{
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.uploading-text{
  font-size: 21px;

  margin-top: 35px;

  margin-bottom: 50px;
}


.alias-description{
  font-size: 21px;
  margin-bottom: 5px;
  color: #333333;
}

.alias-actions{
  font-size: 17px;
}


.save-description{
  font-size: 21px;
  margin-bottom: 5px;
  color: #333333;
}

.save-actions{
  font-size: 17px;

  margin-bottom: 35px;
}

.import-fields{

  background-color: $primary;
  color: #FFFFFF;

  font-size: 17px;

  margin-top: 35px;

  div{
    display: inline-block;
    padding: 10px;
  }

  .field-name{
    width: 35%;
  }

  .field-select{
    width: 65%;
  }
}

.import-items{

  &.song{
    background: none;

    &.even{
      background: rgba($primary, 0.1);
    }
  }

  &::v-deep .v-text-field__details{
    display: none;
  }

  & > div{
    display: inline-block;
    padding: 10px;
  }

  .item-name{
    width: 35%;
    font-size: 17px;

    color: #333333;

    .song-artist{
      font-size: 14px;
      font-style: italic;
    }
  }

  .item-select{
    width: 65%;

    .v-select{
      width: 100%;
    }
  }
}

.validations{
  font-size: 12px;

  text-align: left;
  margin-top: 15px;

  .validation{

    display: inline-block;

    margin-right: 12px;
    .v-icon{
      display: inline-block;
      margin-right: 3px;

      font-size: 14px;
      position: relative;
      top: -1px;
    }
  }
}

.v-card__actions{

  position: relative;

  .refresh-airtables{
    position: absolute;
    margin-left: -12px;
    left: 50%;
  }
}

.parser-item:hover{

  &:before{
    display: none;
  }
}

.v-list-item{
  text-align: left;
}

::v-deep .v-list-item__action{
  position: relative;
}

.parser-options{
  text-align: left;
  margin-bottom: 15px;

  .v-select{
    display: inline-block;
    width: 45%;

    margin-right: 5%;
  }

  .v-text-field{
    display: inline-block;
    width: 45%;
  }

  .parser-actions{

    .duplicate{
      display: inline-block;
      margin-right: 15px;
    }
  }

  .parser-delete{
    height: 115px;

    .delete-holder{
      background-color: rgba($error, 0.15);
      border: 1px solid $error;

      padding: 15px;

      text-align: center;


      position: relative;
      top: 15px;

      p{
        margin-bottom: 5px;
        color: $error;
      }
    }
  }
}

.v-btn.close{
  position: relative;

  top: -5px;
  right: -5px;
}
</style>
